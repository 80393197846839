// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/home/runner/work/sell/sell/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-consign-terms-js": () => import("/home/runner/work/sell/sell/src/pages/consign-terms.js" /* webpackChunkName: "component---src-pages-consign-terms-js" */),
  "component---src-pages-get-started-js": () => import("/home/runner/work/sell/sell/src/pages/get-started.js" /* webpackChunkName: "component---src-pages-get-started-js" */),
  "component---src-pages-index-js": () => import("/home/runner/work/sell/sell/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-thank-you-js": () => import("/home/runner/work/sell/sell/src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

