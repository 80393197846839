import React, { useState, useEffect } from 'react';

export const CookieContext = React.createContext(null);

const cookieKeys = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'anonid',
  'userid',
  'deviceid',
  'gclid',
];

const createCookie = (name, value, days) => {
  let cookie = `${name}=${encodeURIComponent(value)}; path=/; domain=.ebth.com;`;
  if (days) {
    cookie += `; expires=${new Date(Date.now() + (days * 24 * 60 * 60 * 1000)).toGMTString()}`;
  }
  document.cookie = cookie;
};

const readCookie = function readCookie(name) {
  const key = `${name}=`;
  const cookies = document.cookie.split(';');
  return cookies.reduce(function reduceCookies(value, cookie) {
    return cookie.trim().indexOf(key) === 0 ? cookie.split(key)[1] : value;
  }, '');
};

const parseUrlParameters = () => {
  // Query string format ex: domain.ebth.com/?key=value&key2=value2
  const url = window.location.search.toString();
  return url.substring(url.indexOf('?') + 1).split('&').reduce((params, pair) => {
    if (pair) {
      const [key, value] = pair.split('=');
      params[decodeURIComponent(key)] = decodeURIComponent(value);
    }
    return params;
  }, {});
};

const setContext = (additionalContext) => {
  // Context is pulled from query parameters and existing cookies
  const params = parseUrlParameters();
  Object.entries(params).forEach(([key, value]) => createCookie(key, value));

  const cookieValues = cookieKeys.reduce(function lookupCookieValue(cookies, key) {
    cookies[key] = readCookie(key);
    return cookies;
  }, {});

  return { ...params, ...cookieValues, ...additionalContext };
};

const CookieProvider = ({ children }) => {
  const [session, setSession] = useState(setContext());

  useEffect(() => {
    const analyticsTimer = setTimeout(() => {
      if (window.analytics && !!window.analytics.user) {
        setSession(
          setContext({
            anonid: window.analytics.user().anonymousId(),
            userid: readCookie('ajs_user_id').slice(3, -3),
            gclid: readCookie('gclid'),
            analytics: window.analytics,
          })
        );
      }
    }, 1000);
    return () => clearInterval(analyticsTimer);
  }, []);

  return (
    <div>
      <CookieContext.Provider value={session}>
        {children}
      </CookieContext.Provider>
    </div>
  );
};

export default CookieProvider;
